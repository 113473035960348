module.exports = [{
      plugin: require('/app/front/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/app/front/src/layouts/index.tsx"},
    },{
      plugin: require('/app/front/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"hjd4zgx"}},
    },{
      plugin: require('/app/front/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"774820072621293"},
    },{
      plugin: require('/app/front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
